import haircutImage from "../../assets/images/headline_images/new_haircut_image.jpg"
import ProductEntry from "../../components/products/ProductEntry"
import christophGollImage from "../../assets/images/christoph_goll_new.jpeg"
import HorizontalSeparator from "../../components/layout/HorizontalSeparator"
import depotIcon from "../../assets/images/products/depot_icon.jpeg"
import milkshakeIcon from "../../assets/images/products/milkshake_icon.png"
import CustomCarousel from "../../components/carousel/ProductsCarousel"

export default function Products() {
    return <>
        {/*<div className="relative pb-4 cursor-default">
            <div className="flex justify-center">
                <img src={haircutImage} alt="Produkte" className="w-[95%] rounded-sm" />
            </div>

            <div className="absolute bottom-[40%] left-[50%] translate-x-[-50%] z-10">
                <p className="text-3xl font-semibold text-white">PRODUKTE</p>
            </div>
        </div>*/}
        <div className="relative pb-1 sm:pt-[5%] pt-[15%]">
            <div className="absolute bottom-[50%] left-[50%] translate-x-[-50%] translate-y-[50%] z-10">
                <p className="text-2xl sm:text-6xl font-semibold text-white tracking-wide">PRODUKTE</p>
            </div>
        </div>

        <ProductEntry name="Depot" image={depotIcon} description="Jeder Mann hat seine eigene Vorstellung von Schönheit und persönlicher Schönheitsroutine. DEPOT®️ lässt sich von ihren Geschichten inspirieren, um dem Mann, der es liebt, auf sein Äußeres achten, das Gefühl zu geben, immer bereit zu sein, jeder Gelegenheit und jedem Moment des Tages bestmöglich zu begegnen.
Individuelle Produkte für die Haar-, Rasur- und Bartpflege, sowie Körperpflege und Parfüms, um das DEPOT®️-Erlebnis jederzeit und an jedem Tag zu fühlen. Professionelle und vielseitige Produktlinien für die Männerpflege mit hochwertigen Inhaltsstoffen, exklusiver Pflege und einzigartigen Dienstleistungen für Friseure und Kunden." link="https://www.depotmaletools.com/en/" />

        <div className="pt-6 pb-6">
            <HorizontalSeparator />
        </div>

        <ProductEntry name="milk_shake" image={milkshakeIcon} description="Die neue Natürlichkeit. Ganz gleich welcher Haartyp, wir haben das passende milk_shake®️ Produkt für Sie. Pflege und Styling für jedes Haar mit natürlichen Bestandteilen und Inhaltsstoffen, wie zum Beispiel Milch- und Joghurtproteine, sowie Fruchtextrakten.
Spezielle Haarpflege- und Stylingprodukte für natürliche und lebendige Resultate. Von der Reinigung bis zum Styling – jede Phase der Pflege ist darauf ausgerichtet, die Gesundheit und Schönheit des Haares zu erhalten.
Wann immer es möglich ist, sind unsere Formeln frei von: Sulfaten, Parabenen und Salzen." link="https://www.milkshakehair.com" />

        <div className="pt-6 pb-5">
            <HorizontalSeparator />
        </div>

        <div className="flex h-96 justify-center pl-[30%] pr-[30%] ">
            <CustomCarousel />
        </div>

        <div className="pt-5">
            <HorizontalSeparator />
        </div>
    </>
}