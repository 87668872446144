import educationImage from "../../assets/images/headline_images/new_education_image.jpg"
import christophGollImage from "../../assets/images/christoph_goll_new.jpeg"
import { Link } from "react-router-dom"

export default function Education() {
    return <div className="pb-8 cursor-default">
        {/*<div className="relative pb-1">
            <div className="flex flex-row justify-center">
                <img src={educationImage} alt="Education Titelfoto" className="w-[95%] rounded-sm" />
            </div>

            <div className="absolute bottom-[50%] left-[50%] translate-x-[-50%] translate-y-[50%] z-10">
                <p className="text-2xl sm:text-4xl font-semibold text-white tracking-wide">EDUCATION</p>
            </div>
        </div>*/}
        <div className="relative pb-1 sm:pt-[5%] pt-[15%]">
            <div className="absolute bottom-[50%] left-[50%] translate-x-[-50%] translate-y-[50%] z-10">
                <p className="text-2xl sm:text-6xl font-semibold text-white tracking-wide">EDUCATION</p>
            </div>
        </div>

        <div className="pl-[7%] flex justify-start">
            <div className="w-[90%] sm:w-[60%]">
                <p className="text-xl font-semibold pb-3 pt-3">
                    ÜBER EDUCATION
                </p>
                <p>
                    Ich glaube daran, dass großartige Haarstylisten nicht geboren werden, sondern durch Leidenschaft,
                    Wissen und kontinuierliches Lernen wachsen. Meine Mission ist es, Dich auf Deinem Weg zu einem erfolgreichen
                    Hairstylisten oder einer Hairstylistin zu begleiten und Dir die Werkzeuge,
                    Techniken und Inspiration a die Hand zu geben, die Du für Deine Karriere brauchst.
                    <br /><br />
                    Ob Du Deine Grundlagen festigen, neue Trends wie Airtouch erlernen oder Deine Fertigkeiten perfektionieren
                    möchtest - ich biete maßgeschneiderte Bildungsangebote für alle Erfahrungsstufen. Meine Seminare und Workshops werden
                    von den Erfahrungen geleitet die ich als Trainer im Ausland bei Z-one Concept als internationaler Trainer erlernt habe.
                    <br /><br />
                </p>
                <p className="text-lg font-semibold">
                    WARUM HAAR-EDUCATION BEI MIR?
                </p>
                <ul className="pl-[2%] list-disc list-inside m-2">
                    <li>Praxisorientiertes Lernen: Von der Theorie direkt an den Arbeitsplatz - wir legen Wert auf anwendbares Wissen.</li>
                    <li>Individuelle Betreuung: Kleine Gruppen und persönliche Unterstützung garantieren maximale Lernerfolge.</li>
                    <li>Zukunftsorientierte Inhalte: Bleib am Puls der Zeit mit Techniken wie Balayage, Airtouch und anderen innovativen Trends.</li>
                    <li>Inspiration und Networking: Lerne von den Besten und tausche Dich mit anderen Stylisten aus.</li>
                </ul>
                <p>
                    <br />
                    Dein Erfolg ist meine Motivation. Lass uns gemeinsam daran arbeiten, Deine Fähigkeiten auf das nächste Level zu bringen.
                    Starte jetzt Deine Reise mit Christoph Goll Haar-Education, die Dich und Deine Karriere nachhaltig verändert.
                    <br /><br />
                    Entdecke meine Angebote und werde Teil der nächsten Generation von Hairstylisten!
                </p>
                <p className="pt-4">
                    Finden Sie jetzt Ihr passendes Seminar unter&nbsp;<Link
                        to="/seminare" className="font-bold cursor-pointer">SEMINARE</Link>
                </p>
            </div>

            <div className="pl-[15%] pt-[15%] hidden sm:block">
                <img src={christophGollImage} alt="Christoph Goll" className="w-40 h-40 rounded-full overflow-hidden object-cover" />
            </div>
        </div>
    </div>
}