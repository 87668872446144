interface Props {
    image: any
    title: string
    details: string
    duration: string
    description: string
    extra: string
    targetGroup: string
    price: string
}

const SeminarEntry: React.FC<Props> = ({ image, title, details, duration, description, extra, targetGroup, price }) => {
    return <div className="flex-wrap hover:bg-gray-800 hover:rounded-md p-2">
        <div className="block">
            <img src={image} alt="Christoph Goll" className="w-28 h-28 rounded-full overflow-hidden object-cover" />
        </div>

        <p className="font-semibold pt-3 text-lg">
            {title}
        </p>

        <p className="pt-3">
            {description}
        </p>

        <p className="pt-8 text-sm pl-[3%]">
            {details}
        </p>

        <p className="pt-3 text-sm pl-[3%]">
            Dauer: {duration}
        </p>

        {extra === "" ? <></> : <p className="pt-3 text-sm pl-[3%]">
            {extra}
        </p>}

        <p className="pt-3 text-sm pl-[3%]">
            {targetGroup}
        </p>

        <p className="pt-3 text-sm pl-[3%]">


            <div className="pt-1 flex flex-row">
                {price}
                <p>&nbsp;unter&nbsp;</p>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" className="w-5 h-5 mr-2 ">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                </svg>

                <a href="tel:+436603143190">
                    +43 660 3143190
                </a>
            </div>
        </p>
    </div>
}

export default SeminarEntry